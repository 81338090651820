<template>
  <div
    class="form"
    id="commonForm"
  >
    <div class="form-group">
      <div class="form-group__item">
        <input
          v-model="login"
          type="text"
          class="form-control"
          placeholder="Login"
        >
      </div>
      <div class="form-group__item">
        <input
          type="password"
          v-model="password"
          class="form-control"
          placeholder="Parolă"
          autocomplete="new-password"
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FormAuth',
  data() {
    return {
      login: '',
      password: '',
    };
  },
  methods: {
    reset() {
      this.login = '';
      this.password = '';
    },
    transmitParams() {
      return {
        login: this.login,
        password: this.password,
      };
    },
  },
};
</script>
