import { authHeader, prepeareUrl } from '../_helpers';
import { ResponseHandler } from '../_helpers/response-handler';

export const resultsService = {
  getAll,
};

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  const url = prepeareUrl('/commandList');
  return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response
    .text()
    .then((text) => ResponseHandler.parseResponse(text, response.status));
}
