<template>
  <div id="app">
    <notifications
      group="sau-template"
      classes="sau-notice"
      animation-name="v-fade-right"
      position="top right"
    />
    <component :is="layout" />
  </div>
</template>
<script>
import defaultLayout from '@/layouts/defaultLayout';
import error404 from '@/layouts/error404';

export default {
  components: {
    defaultLayout,
    error404,

  },
  created() {
    window.linkToApp = this;
  },
  computed: {
    layout() {
      // eslint-disable-next-line no-prototype-builtins
      return (this.$route.meta && this.$route.meta.hasOwnProperty('layout'))
        ? this.$route.meta.layout
        : 'default-layout';
    },
  },
};
</script>
<style lang="scss">
  @import "assets/scss/common-style";
  #app{
    margin: 0 auto;
    position: relative;
    background: url("assets/img/bg/main-bg-1920.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 360px;
    scroll-behavior: smooth;
    overflow: hidden;
    min-height: 100vh!important;

    @include md {
      background: url("assets/img/bg/main-bg-1000.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    @include sm {
      background: url("assets/img/bg/main-bg-768.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    @include sm-phone-width {
      background: url("assets/img/bg/main-bg-480.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .vue-notification-group{
    width: auto!important;
    min-width: 300px;
    top: 90px!important;
  }
  .sau-notice {
    padding: 20px 10px;
    margin: 20px 0 5px 0;
    font-size: 14px;
    color: #ffffff;
    background: $block-bg-color;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid transparent;
    border-right: 0;
    @media (max-width: 576px) {
      padding: 10px;
      margin: 10px 0 5px 0;
    }
    &.warn {
      border-color: #ffb648;
      background: $block-bg-color;
      .notification-title{
        color: #ffb648;
      }
    }

    &.error {
      border-color: $normal-red;
      background: $block-bg-color;
      .notification-title{
        color: $normal-red;
      }
    }

    &.success {
      border-color: $success-color;
      background: $block-bg-color;
      .notification-title{
        color: $success-color;
      }
    }
    .notification-title{
      font-size: 16px;
      @media (max-width: 576px) {

      }
    }
  }
  .v-fade-right-enter,
  .v-fade-right-leave-to {
    opacity: 0;
    transform: translateX(100%) scale(0.9);
  }

</style>
