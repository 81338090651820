import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home'),
  },
  {
    path: '/conditions',
    name: 'Conditions',
    component: () => import('../views/Conditions'),
  },

  {
    path: '/404',
    name: '404',
    component: () => import('../views/Page404'),
    meta: {
      layout: 'error404',
    },
  },
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/streaming/:streamer_name',
    name: 'Streaming',
    component: () => import('../views/Streaming.vue'),
  },

];
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
export default router;
