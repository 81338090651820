<template>
  <div>
    <preloader />
    <router-view />
  </div>
</template>

<script>
import Preloader from '@/components/Preloader';

export default {
  name: 'Error404',
  components: {
    Preloader,
  },
};
</script>
