<template>
  <div
    v-if="participant"
    class="form"
    ref="form"
    id="commonForm"
  >
    <div class="user_information">
      <div class="user_information__item">
        Poreclă: <span>{{ participant.name }}</span>
      </div>
      <div class="user_information__item">
        Punctaj: <span>{{ participant.point }}</span>
      </div>
      <div class="user_information__item">
        Poziția din echipă: <span>{{ participant.place }}</span>
      </div>
    </div>
    <div
      :key="name"
      v-for="(data, name) in computedProjects"
      class="form-group"
    >
      <!--
            Object data : {
              status: Number (принимает значения 1,2,3)
              projects: Object,
            }
      -->
      <div class="form-group__item">
        <div
          v-if="data.status === 2"
          class="action-combined"
        >
          <radio-button
            :label="name"
            :value="main_project"
            @change="chooseMainProject"
          />
        </div>
        <input
          v-model="computedProjects[name].email"
          :disabled="data.status === 1 || data.status === 2"
          type="email"
          :class="{'input-combined' : data.status === 2}"
          class="form-control email-placeholder"
          :placeholder="`${name[0].toUpperCase() + name.slice(1)} Email`"
        >
        <div
          v-if="data.status"
          class="field-description"
        >
          Stare: <span :class="getStatusProject(data.status)">{{ data.status === 1 ? 'În așteptare' :
            data.status === 2 ? 'Aprobat' :
            data.status === 3 ? 'Respins' : '' }}</span>
        </div>
      </div>
    </div>

    <div class="form-group">
      <p class="text-gray">
        Te rugăm alege unde vrei să primești recompensa.
      </p>
      <p class="text-gray">
        Premiul va fi creditat în
        <span class="text-white"> {{ getMainCreditProject }}</span> contul tău.
      </p>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import RadioButton from './RadioButton.vue';

export default {
  name: 'FormEdit',
  props: {
    isAuthorized: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      projects: {
        frank: {
          email: '',
        },
        slotv: {
          email: '',
        },
        mrbit: {
          email: '',
        },
      },
      main_project: null,
    };
  },
  components: {
    RadioButton,
  },
  computed: {
    ...mapState({
      participant: (state) => state.account.participant,
    }),
    getMainCreditProject() {
      return this.main_project
        ? this.main_project[0].toUpperCase() + this.main_project.slice(1)
        : 'Neselectat';
    },
    computedProjects() {
      return (this.participant.projects)
        ? { ...this.projects, ...this.participant.projects }
        : this.projects;
    },
  },
  watch: {
    participant(val) {
      this.main_project = val ? val.main_project : null;
    },
  },
  methods: {
    transmitParams() {
      return {
        projects: Object.fromEntries(Object.entries(this.computedProjects).map(([key, val]) => [key, val.email])),
        main_project: this.main_project,
      };
    },
    // eslint-disable-next-line consistent-return
    getStatusProject(status) {
      switch (status) {
        case 1:
          return 'warning';
        case 2:
          return 'success';
        case 3:
          return 'error';
        default:
          break;
      }
    },
    chooseMainProject(val) {
      this.main_project = val;
    },
  },
};
</script>
<style lang="scss">
  .user_information {
    color: $normal-gray;
    text-align: left;
    margin-bottom: 20px;
    &__item span{
        color: $normal-green
    }
  }
</style>
