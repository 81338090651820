export function authHeader() {
  const user = JSON.parse(localStorage.getItem('participant'));
  if (user && user.token) {
    return {
      Authorization: `Bearer ${user.token}`,
      'Content-Type': 'application/json',
    };
  }
  return {};
}
