import { enumsService } from '@/services';

const state = {
  date: {},
  error: null,
};

const actions = {
  getContestDates({ commit }) {
    commit('getDatesRequest');
    return enumsService.getContestDates().then(
      (res) => commit('getDatesSuccess', res),
      (error) => commit('getDatesFailure', error),
    );
  },
};

const mutations = {
  getDatesRequest(state) {
    state.date = {};
  },
  getDatesSuccess(state, res) {
    state.date = res;
  },
  getDatesFailure(state, error) {
    state.error = error;
  },
};

export const enums = {
  namespaced: true,
  state,
  actions,
  mutations,
};
