import { authHeader } from '../_helpers';
import { ResponseHandler } from '../_helpers/response-handler';
import { prepeareUrl } from '../_helpers/request-helpers';

export const streamingService = {
  getStreamer,
  getAllStreamers,
};

function getAllStreamers() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const url = prepeareUrl('/streamers');
  return fetch(url, requestOptions).then(handleResponse);
}

function getStreamer(params) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  const url = prepeareUrl(`/streamerPage/${params}`);
  return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response
    .text()
    .then((text) => ResponseHandler.parseResponse(text, response.status));
}
