import { streamingService } from '@/services';

const state = {
  streamer: [],
  loading: false,
  channels: [],
  followers: [],
  platform: {},
  stream: {},
};

const actions = {
  getAll({ commit }) {
    commit('getAllRequest');
    return streamingService.getAllStreamers().then(
      (res) => commit('getAllSuccess', res),
      (error) => commit('getAllFailure', error),
    );
  },
  getStreamer({ commit }, params) {
    commit('getStreamerRequest');
    return streamingService.getStreamer(params).then(
      (streamer) => commit('getStreamerSuccess', streamer),
      (error) => commit('getStreamerFailure', error),
    );
  },
};

const mutations = {
  getAllRequest(state) {
    state.loading = true;
  },
  getAllSuccess(state, res) {
    state.items = res.streamers;
  },
  getAllFailure(state, error) {
    state.error = error;
  },
  getStreamerRequest(state) {
    state.loading = true;
  },
  getStreamerSuccess(state, streamer) {
    state.streamer = streamer;
  },
  getStreamerFailure(state, error) {
    state.error = error;
  },
};

export const streamers = {
  namespaced: true,
  state,
  actions,
  mutations,
};
