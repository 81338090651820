<template>
  <div
    class="form"
    ref="form"
    name="commonForm"
    id="commonForm"
  >
    <div class="form-group">
      <div class="form-group__item">
        <input
          v-model="regParams.login"
          type="text"
          class="form-control has-hint"
          placeholder="Playattack Login"
        >
        <span
          class="hint"
          v-popover:playattackLoginHint.left
        >?</span>
      </div>
      <div class="form-group__item">
        <input
          v-model="regParams.name"
          type="text"
          class="form-control"
          placeholder="Poreclă"
          autocomplete="no"
        >
      </div>
      <div class="form-group__item">
        <input
          type="password"
          v-model="regParams.password"
          class="form-control"
          placeholder="Parolă"
          autocomplete="new-password"
        >
      </div>
      <div class="form-group__item">
        <input
          type="password"
          v-model="regParams.password_confirmation"
          class="form-control"
          placeholder="Confirmă parola"
          autocomplete="new-password"
        >
      </div>
      <div class="form-group__item pos-rel">
        <input
          v-model="regParams.channel"
          type="text"
          class="form-control has-hint"
          placeholder="Link către canal"
        >
        <span
          class="hint"
          v-popover:channelHint.left
        >?</span>
      </div>
      <div class="form-group__item pos-rel">
        <popover
          :key="keyPopover"
          event="click"
          name="channelHint"
        >
          Pentru YouTube, te rugăm să folosești linkul canalului cu ID-ul, nu pe cel cu numele de utilizator. De
          exemplu,
          <br>
          <span class="text-aquablue">"https://www.youtube.com/channel/UC-lHJZR3Gqxm24_Vd_AJ5Ywa"</span>
          <br>
          va funcționa și
          <br>
          <span class="text-red">"https://www.youtube.com/user/TOPRACER"</span> nu va funcționa.
        </popover>
        <popover
          :key="keyPopover"
          event="click"
          pointer="false"
          name="playattackLoginHint"
        >
          Dacă dorești să participi ca și streamer și nu ai un cont PlayAttack - contactează-ne la
          <a
            href="mailto:theshowdown@playattack.com"
            class="text-aquablue"
          >theshowdown@playattack.com</a>
          și oferă-ne un link către canalul tău.
        </popover>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FormRegistration',
  props: {
    registerForm: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      regParams: {
        login: '',
        name: '',
        password: '',
        password_confirmation: '',
        channel: '',
      },
      keyPopover: 0,
    };
  },
  created() {
    this.keyPopover += 1;
  },
  methods: {
    reset() {
      for (const i in this.regParams) {
        this.regParams[i] = '';
      }
    },
    transmitParams() {
      return this.regParams;
    },
  },
};
</script>

<style scoped lang="scss">
[data-popover='channelHint'],
[data-popover='playattackLoginHint'] {
  max-width: 400px !important;
  width: 100% !important;
  background: $block-bg-color;
  color: $normal-white;
  font-size: 12px;
  line-height: 1.5;
  border: 1px solid $secondary-color;
  &:before {
    display: none;
  }
  @media(max-width: 464px) {
    max-width: 300px!important;
  }
  @media(max-width: 359px) {
    max-width: 85%!important;
  }
}
</style>
