export function convertObjectToUrl(params) {
  return Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');
}

export function prepeareUrl(route, params) {
  let url = `${process.env.VUE_APP_API}${route}`;
  const { hostname } = window.location;
  if (hostname.match('.club')) {
    url = `${process.env.VUE_APP_API_CLUB}${route}`;
  }
  if (params != null) {
    const urlParams = convertObjectToUrl(params);
    url += `?${urlParams}`;
  }
  return url;
}
