import { ResponseHandler } from '../_helpers/response-handler';
import { prepeareUrl } from '../_helpers/request-helpers';

function handleResponse(response) {
  return response
    .text()
    .then((text) => ResponseHandler.parseResponse(text, response.status));
}

function getContestDates() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const url = prepeareUrl('/date');
  return fetch(url, requestOptions).then(handleResponse);
}

export const enumsService = {
  getContestDates,
};
