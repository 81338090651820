<template>
  <div>
    <Preloader />
    <Header ref="header" />
    <router-view @callHeaderModal="callHeaderModal" />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header';
import Preloader from '@/components/Preloader';
import Footer from '@/components/Footer';

export default {
  name: 'DefaultLayout',
  components: {
    Header,
    Footer,
    Preloader,
  },
  methods: {
    callHeaderModal(openedSlot) {
      this.$refs.header.callRegModal(openedSlot);
    },
  },
};
</script>
