import Vue from 'vue';
import Notifications from 'vue-notification';
import Popover from 'vue-js-popover';
import AsyncComputed from 'vue-async-computed';
import store from './store';
import router from './router';
import App from './App.vue';

window.linkToVue = Vue;
Vue.use(Notifications);
Vue.use(Popover, { tooltip: true });

Vue.use(AsyncComputed);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
