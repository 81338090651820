import { resultsService } from '@/services';

const state = {
  loading: true,
  items: [],
};

const actions = {
  getAll({ commit }) {
    commit('getAllRequest');
    return resultsService.getAll().then(
      (response) => commit('getAllSuccess', response),
      (error) => commit('getAllFailure', error),
    );
  },
};

const mutations = {
  getAllRequest(state) {
    state.loading = true;
  },
  getAllSuccess(state, response) {
    state.items = response.result;
    state.loading = false;
  },
  getAllFailure(state, error) {
    state.error = error;
  },
};
export const results = {
  namespaced: true,
  state,
  actions,
  mutations,
};
