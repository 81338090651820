import Vue from 'vue';
import Vuex from 'vuex';
import { account } from './account.module';
import { streamers } from './streamers.module';
import { results } from './results.module';
import { enums } from './enums.module';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    account,
    streamers,
    results,
    enums,
  },
});
export default store;
