<template>
  <footer class="footer">
    <nav>
      <ul class="nav">
        <li class="nav__link">
          <router-link to="/conditions">
            Termeni si conditii
          </router-link>
        </li>
        <li class="nav__link">
          <a href="https://playattack.com">Program de afiliere</a>
        </li>
      </ul>
      <div class="footer__logo m-b-20 font-20">
        Avento MT Ltd.
      </div>
    </nav>
    <div class="onjn-logo">
      <a
        href="http://onjn.gov.ro/"
        target="_blank"
      >
        <img
          src="@/assets/img/logos/logo_onin.png"
          alt="onjn-license"
        >
      </a>
      <img
        class="m-l-20"
        src="@/assets/img/logos/18-plus-circle.svg"
      >
    </div>
    <div class="footer__license">
      <div>
        <p class="m-b-25">
          Atenție: Jocurile de noroc pot provoca dependență.
          <span><a
            class="text-link"
            href="https://jocresponsabil.ro/"
            target="_blank"
            rel="noopener"
          >Joc responsabil</a></span>
        </p>
        <p class="m-b-0">
          Sediul social și adresa fizică: Nivel 6, Ta Xbiex Business Centre, strada Testaferrata, Ta’ Xbiex, XBX1403,
          Malta.<br>
          Avento MT Limited este o companie înregistrată în conformitate cu legislația malteză cu numărul de
          înregistrare
          C 82766 și operată în baza unei licențe acordate de Oficiul Național pentru Jocuri de Noroc din <br>
          România (ONJN) la data de 1 iunie 2021 cu numărul de licență L1213854W001295.
        </p>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'Footer',
};
</script>

<style lang="scss">
  .footer{
    padding: 80px 10px;
    color: $secondary-color;
    &__logo{
      padding: 16px 10px 0;
      text-align: center;
    }

    .onjn-logo{
      margin: 20px auto;
      text-align: center;
    }

    @include sm {

    }

    &__license {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      max-width: 860px;
      text-align: center;
      color: $secondary-color;
    }
  }
  .nav{
    text-align: center;
    list-style: none;
    margin: 0;
    padding: 0;
    &__link{
      display: inline-block;
      color: $white;
      font-size: 20px;
      margin: 0 15px;
      @include md {
        margin: 0 32px;
      }
      @include sm {
        margin: 0;
        white-space: normal;
        &:first-child {
          margin: 0 32px 0 0;
        }
      }
      &:hover{
        color: $white;
        cursor: pointer;
      }
      &_social{
        transition: all .3s;
        margin: 16px 16px;
        @media (max-width: 576px) {
          margin: 8px 16px;
        }
        &:hover{
          transform: scale(1.2);
        }
      }
    }
  }

</style>
