<template>
  <transition name="fade">
    <div
      v-if="showPreloader"
      id="loader-wrapper"
    >
      <div id="preloader">
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Preloader',
  data() {
    return {
      showPreloader: true,
    };
  },
  mounted() {
    if (this.showPreloader) { this.showToggle(); }
  },
  methods: {
    showToggle() {
      setTimeout(() => {
        this.showPreloader = false;
      }, 2000);
    },
  },
};
</script>

<style scoped lang="scss" >
  #loader-wrapper {
    position: fixed;
    background: #000225;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
  }

  #preloader {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 200px;
    max-height: 100px;
    margin: 0 auto;
    transform: translateY(-150%);
  }

  #preloader span {
    display: block;
    position: absolute;
    border-radius: 16px;
    height: 8px;
    top: 180px;
    left: 0px;
    width: 32px;
    bottom: 0;
    background: $primary-color;
    animation: preloader 2.5s infinite cubic-bezier(0.4, 0.0, 0.2, 1) ;
  }

  #preloader span:nth-child(2){
    left: 40px;
    top: 180px;
    animation-delay: 100ms;
  }

  #preloader span:nth-child(3){
    left: 80px;
    top: 180px;
    animation-delay: 200ms;
  }

  #preloader span:nth-child(4){
    left: 120px;
    top: 180px;
    animation-delay: 300ms;
  }

  #preloader span:nth-child(5){
    left: 160px;
    top: 180px;
    animation-delay: 400ms;
  }

  @keyframes preloader {
    0% { height: 8px; transform: translateY(0px); background: #350092;   }
    25% { height: 60px; transform: translateY(-14px); background: $primary-color;
    }
    50% {height:4px; transform: translateY(0px); background: $primary-color;
    }
    100%{ height:4px; transform:translateY(0px) ;background: $secondary-color;
    }
  }

  #label {
    margin-top: 300px
  }

  p {
    color: #636363;
    font-size: .889em;
    line-height: 1.2;
    font-family: 'Inconsolata', sans-serif;
    text-align: center;

  }
  h2 {
    color: #fafafa;
    font-size: 1.777em;
    line-height: 1.2;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }
</style>

<style scoped lang="css">

  /* VUE TRANSITIONS */

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
