<template>
  <transition
    appear
    name="modal"
  >
    <div
      v-show="showModal"
      class="modal-mask"
      id="modal"
    >
      <div
        @mousedown.self="closeModal($event)"
        @touchstart.self="closeModal($event)"
        class="modal-wrapper"
      >
        <div class="modal-container__outer">
          <h2 class="modal-title">
            {{ modalTitle }}
          </h2>
          <div
            id="modal-container"
            class="modal-container"
          >
            <div class="modal-header">
              <slot name="header" />
            </div>
            <div class="modal-body">
              <slot name="body">
                default body
              </slot>
              <slot name="article" />
            </div>
            <div class="modal-footer">
              <v-button
                classes="btn-secondary m-l-15"
                @click="closeModal"
              >
                Închide
              </v-button>
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Button from '@/components/UI/Button';

export default {
  name: 'Modal',
  components: {
    'v-button': Button,
  },
  props: {
    modalTitle: {
      type: String,
      default: '',
    },
    parentRefs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  created() {
  },
  methods: {
    openModal() {
      this.showModal = true;
      const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;
      const header = document.querySelector('.header__content');
      const { body } = document;
      const headerPaddingRight = window.getComputedStyle(header).getPropertyValue('padding-right');
      header.style.paddingRight = `${parseInt(headerPaddingRight, 10) + scrollBarGap}px`;
      body.style.cssText = `overflow: hidden;  padding-right: ${scrollBarGap}px`;
    },
    closeModal() {
      this.showModal = false;
      if (this.parentRefs.length) {
        this.parentRefs.forEach((el) => {
          if (this.$parent.$refs[el]) {
            this.$parent.$refs[el].reset();
          }
        });
      }
      setTimeout(() => {
        document.querySelector('.header__content').removeAttribute('style');
        document.body.removeAttribute('style');
      }, 100);
    },
  },
};
</script>

<style lang="scss">
  .modal-title{
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: $white;
    display: inline-block;
    text-align: center;
  }
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,2,37,0.9);
    transition: opacity .3s ease;
    overflow-y: auto;
  }
  .modal-wrapper {
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    height: 100%;
    display: flex;
    text-align: center;
  }
  .modal-container {
    border-radius: 20px;
    background: linear-gradient(0deg, #171938 0%, #171938 100%), #FFF;
    width: inherit;
    &__outer {
      transition: all .3s ease;
      width: 100%;
      max-width: 460px;
      margin: 0 auto;
    }
  }
  .modal-header{
    display: flex;
    position: relative;
    justify-content: center;
    &-tab {
      font-size: 18px;
      line-height: 1;
      color: $dark-gray;
      box-sizing: border-box;
      border-bottom: 1px solid $page-bg-color;
      padding: 1.3em;
      flex-basis: 50%;
      background: transparent;
      cursor: pointer;
      &:hover, &.active {
        color: $normal-white;
      }
      &.active {
        border-bottom: 2px solid $primary-color;
      }
    }
  }
   .modal-body {
    margin-top: 5px;
    padding: 20px 24px 0;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    @media (max-width: 576px) {
      padding: 20px 10px 0;
      max-height: calc(100vh - 150px);
    }
    scrollbar-color: rgba(12,77,188,.4) transparent;
    scrollbar-width: thin;
  }
  .modal-body::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }
  .modal-body::-webkit-scrollbar,.modal-body::-webkit-scrollbar-track {
    background: $dark-gray;
    border-radius: 3px;
  }
  .modal-body::-webkit-scrollbar-thumb {
    background: $normal-red;
    border-radius: 3px;
  }
  .content{
    transition: all 1s;
  }
  .modal-footer{
    padding: 10px 10px 20px 10px;
    display: flex;
    justify-content: center;
  }

  // VUE CLASSES
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-leave-active .modal-container__outer {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  .modal-enter .modal-container__outer {
    transform: scale(0);
  }
  .fade-enter-active, .fade-leave-active {
    transition: all 1s;
    opacity: 1;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
</style>
