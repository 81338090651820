<template>
  <button
    :class="['btn', classes]"
    :id="id"
    :name="name"
    :disabled="disabled"
    @click="handleClick"
  >
    <span class="btn__inner"><slot>Button</slot></span>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    id: {
      type: String,
      default: null,
    },
    classes: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss">

.btn {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-family: inherit;
  padding: 8px 30px;
  font-size: 1rem;
  text-decoration: none;
  line-height: normal;
  outline: 0;
  transition: all .1s;
  border: solid 3px;
  border-radius: 9999px;
  color: $white;

  &-primary {
    border-color: $primary-color;
    background: $primary-color;
    &:hover {
      border-color: $primary-color;
      background: transparent;
      color: $white;
    }
  }

  &-secondary {
    border-color: $white;
    background: transparent;
    &:hover {
      border-color: $primary-color;
      background: $primary-color;
      color: $white;
    }
  }

  &-w-md {
    padding: 19px 54px;
    font-size: 20px;
    border-width: 3px;
    @include md {
      padding: 10px 20px;
    }
  }
  &-w-lg {
    padding: 10px 20px;
    @include md {
      border: 1px solid;
      padding: 10px 20px;
    }
  }
}
</style>
