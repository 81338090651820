import { userService } from '@/services';

const user = JSON.parse(localStorage.getItem('user'));
const state = user
  ? {
    status: null,
    token: user.token,
    user,
    error: null,
    participant: {},
    projects: {},
  }
  : {
    status: null,
    token: '',
    user: null,
    error: null,
    participant: {},
    projects: {},
  };

const actions = {
  login({ commit }, { login, password }) {
    commit('loginRequest', { login, password });
    const promise = userService.login(login, password).then(
      (user) => commit('loginSuccess', user),
      (error) => commit('loginFailure', error),
    );
    return promise;
  },

  logout({ commit }) {
    userService.logout();
    commit('logout');
    window.location.replace(window.location.origin);
  },

  register({ commit }, user) {
    commit('registerRequest', user);
    const resultPromise = userService.register(user).then(
      (status) => commit('registerSuccess', status),
      (error) => commit('registerFailure', error),
    );
    return resultPromise;
  },

  updateUser({ commit }, params) {
    commit('updateUser', params);
    const resultPromise = userService.updateUser(params).then(
      (status) => commit('updateUserSuccess', status),
      (error) => commit('updateUserFailure', error),
    );
    return resultPromise;
  },

  getParticipant({ commit }) {
    const resultPromise = userService.getParticipant().then(
      (participant) => commit('getParticipantSuccess', participant),
      (error) => commit('getParticipantFailure', error),
    );
    return resultPromise;
  },
};

const mutations = {
  loginRequest(state, user) {
    state.user = user;
  },
  loginSuccess(state, user) {
    state.user = user;
    if (user.token) {
      localStorage.setItem('participant', JSON.stringify(user));
    }
  },
  loginFailure(state, error) {
    state.user = null;
    state.error = error;
  },
  logout(state) {
    state.user = null;
  },

  registerRequest(state) {
    state.status = null;
  },
  registerSuccess(state, status) {
    state.status = status;
  },
  registerFailure(state, error) {
    state.status = null;
    state.error = error;
  },

  getParticipantSuccess(state, participant) {
    state.participant = participant;
  },
  getParticipantFailure(state, error) {
    state.error = error;
  },

  updateUser(state) {
    state.status = null;
  },
  updateUserSuccess(state, status) {
    state.status = status;
  },
  updateUserFailure(state, error) {
    state.status = null;
    state.error = error;
  },
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
};
