/**
 * Class for response parsing
 *
 * @returns {ResponseHandlerClass}
 * @constructor
 */
function ResponseHandlerClass() {
  const self = this;

  self.response_code = null;
  self.response_data = null;
  self.response_meta = null;

  self.success_responses = [200, 201, 202, 203, 204, 205, 206];
  self.error_responses = [400, 404, 401];

  self.parseResponse = (response, status, msg = null) => {
    if (status === 404) {
      window.location.replace('/404');
    } else if (status === 401) {
      window.localStorage.removeItem('participant');
      window.location.replace('/');
    }
    response = response && JSON.parse(response);
    self.response_code = status && status === response.code ? response.code : status;
    self.response_data = response.data;
    self.response_meta = response.meta;
    if (self.isSuccess() && self.response_meta.method !== 'GET') {
      window.linkToApp.$notify({
        group: 'sau-template',
        type: 'success',
        title: 'Succes',
        text: msg,
        duration: 2000,
        speed: 300,
      });
    }
    if (!self.isSuccess()) {
      const msg = self.showError();
      return Promise.reject(msg);
    }
    return Promise.resolve(this.response_data);
  };
  self.isSuccess = () => self.success_responses.indexOf(self.response_code) >= 0;
  self.isError = () => self.error_responses.indexOf(self.response_code) >= 0;
  self.showError = () => {
    const errorTitle = () => (self.response_data.message
      ? JSON.stringify(self.response_data.message).replace(
        /^[\S\s]|[\S\s]$/g,
        '',
      )
      : self.response_data);

    const errorText = () => {
      const prepareText = () => Object.values(self.response_data.details)
        .map((el) => ((el.length > 1) ? el.splice(0, 1) : el));
      const details = self.response_data.details ? prepareText() : [];
      return details.join('<br>');
    };

    window.linkToApp.$notify({
      group: 'sau-template',
      type: 'error',
      title: errorTitle(),
      text: errorText(),
      duration: 5000,
      speed: 300,
    });
    let msg = null;
    try {
      msg = self.response_data;
      if (process.env.NODE_ENV === 'development') {
        console.warn(self.response_data);
        console.warn(self.response_meta);
      }
    } catch (e) {
      msg = 'empty error message';
      console.log(msg);
    }
    return msg;
  };
  return this;
}
export const ResponseHandler = new ResponseHandlerClass();
