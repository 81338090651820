<template>
  <div class="radio">
    <input
      type="radio"
      :value="label"
      v-model="radioButtonValue"
      class="radio__check"
    >
    <div class="radio__indicator" />
  </div>
</template>

<script>
export default {
  name: 'RadioButton',
  props: {
    value: {
      type: String,
      Number,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    radioButtonValue: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('change', this.label);
      },
    },
  },
};
</script>

<style lang="scss">
.radio {
  display: inline-block;
  height: 16px;
  width: 28px;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
}
.radio__check {
  z-index: 2;
  opacity: 0;
  cursor: pointer;
  &:checked {
    & ~ .radio__indicator {
      background: $primary-color;
      &:after {
        transform: translate(12px, 0);
      }
    }
  }
}
.radio__check,
.radio__indicator {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
}
.radio__indicator {
  background: $normal-gray;
  transition: background 0.25s ease;
  &:after {
    height: 12px;
    width: 12px;
    background: $normal-white;
    content: '';
    position: absolute;
    border-radius: 100%;
    top: 2px;
    left: 2px;
    transition: transform 0.25s ease;
  }
}
</style>
