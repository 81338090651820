<template>
  <header
    id="header"
    class="header fixed-top"
  >
    <div class="header__content">
      <!--DESKTOP MENU-->

      <nav class="header__menu desktop-nav">
        <router-link
          tag="a"
          to="/conditions"
          class="desktop-nav__link"
        >
          Termeni si conditii
        </router-link>
        <a
          href="https://playattack.com"
          class="desktop-nav__link"
        >Program de afiliere</a>
      </nav>

      <!--DESKTOP MENU END-->

      <div class="header__buttons">
        <div
          class="menu-icon"
          @click="toggleMenu"
        >
          <transition
            name="icon"
            mode="out-in"
          >
            <img
              v-if="showMenu"
              key="close"
              src="@/assets/img/icons/icon_menu_close.svg"
              alt="toggle menu"
            >
            <img
              v-else
              key="open"
              src="@/assets/img/icons/icon_menu.svg"
              alt="toggle menu"
            >
          </transition>
        </div>
        <!-- if user non authorized -->
        <div
          v-if="!isAuthorized"
          style="display: inline-block; white-space: nowrap"
        >
          <v-button
            classes="btn-secondary btn-w-lg"
            @click="callRegModal('authSlot')"
          >
            Logare
          </v-button>
          <v-button
            classes="btn-secondary btn-w-lg"
            @click="callRegModal('registrationSlot')"
          >
            Creează un cont
          </v-button>
        </div>
        <div
          v-if="isAuthorized"
          style="display: inline-block; white-space: nowrap"
        >
          <v-button
            classes="btn-secondary btn-w-lg"
            v-if="participantType !== 'streamer'"
            @click="controlEditModal(),
                    slotsSwitcher('editSlot')"
          >
            <span class="d-576-none">Editează</span> Profil
          </v-button>
          <a
            v-if="participantType === 'streamer'"
            :href="`/streaming/${streamerName}`"
            class="btn btn-secondary btn-w-lg"
          >
            Pagina ta
          </a>
          <v-button
            classes="btn-secondary btn-w-lg"
            @click="logOut"
          >
            Delogare
          </v-button>
        </div>
      </div>
    </div>

    <!--MOBILE MENU-->

    <transition name="menu">
      <div
        class="nav-mobile"
        v-if="showMenu"
        :class="{open : showMenu}"
      >
        <ul
          role="navigation"
          class="nav"
        >
          <router-link
            tag="li"
            to="/conditions"
            class="nav__item"
          >
            <a
              @click="closeMenu"
              class="nav__link"
            >Termeni si conditii</a>
          </router-link>
          <li class="nav__item">
            <a
              href="https://playattack.com"
              class="nav__link"
            >Program de afiliere</a>
          </li>
        </ul>
      </div>
    </transition>

    <!-- EDIT MODAL -->
    <modal
      ref="editModal"
      :modal-title="'Editează profil'"
    >
      <div slot="header" />
      <div
        slot="body"
        class="content"
      >
        <form-edit
          :is-authorized="isAuthorized"
          ref="formEditProfile"
        />
      </div>
      <div slot="footer">
        <div v-show="slots.editSlot">
          <v-button
            @click="saveChangesInProfile()"
            :disabled="waitingResponse"
            classes="btn-secondary m-l-15"
          >
            Salvare modificări
          </v-button>
        </div>
      </div>
    </modal>

    <!--REG AND AUTH MODAL-->

    <modal
      ref="modal"
      :parent-refs="['authForm', 'streamerRegForm', 'playerRegForm']"
      :modal-title="slots.registrationSlot ? 'Inregistreaza-te' : 'Logare'"
    >
      <template
        slot="header"
        v-if="slots.registrationSlot"
      >
        <div
          class="modal-header-tab"
          :class="{active: activeTab === 'streamer'}"
          @click="activeTab = 'streamer', toggleRegViewsHandler('playerRegForm')"
        >
          Streamer
        </div>
        <div
          class="modal-header-tab"
          :class="{active: activeTab === 'player'}"
          @click="activeTab = 'player', toggleRegViewsHandler('streamerRegForm')"
        >
          Jucător
        </div>
      </template>
      <div
        slot="body"
        class="content"
      >
        <!--Login-->
        <div v-show="slots.authSlot">
          <form-auth ref="authForm" />
        </div>
        <!--Tabs-->
        <div
          class=""
          v-if="slots.registrationSlot"
        >
          <form-streamer-reg
            v-if="activeTab === 'streamer'"
            ref="streamerRegForm"
          />
          <form-player-reg
            v-else
            ref="playerRegForm"
          />
        </div>
        <!--End tabs-->
      </div>
      <div slot="footer">
        <div v-show="slots.registrationSlot">
          <v-button
            @click="registration"
            :disabled="waitingResponse"
            classes="btn-secondary m-l-15"
          >
            Înregistrează-te
          </v-button>
        </div>
        <div v-show="!slots.registrationSlot">
          <v-button
            @click="auth"
            :disabled="waitingResponse"
            classes="btn-secondary m-l-15"
          >
            Login
          </v-button>
        </div>
      </div>
    </modal>
  </header>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import Modal from '@/components/UI/Modal.vue';
import Button from '@/components/UI/Button.vue';
import FormStreamerRegistration from './UI/Forms/FormStreamerRegistration';
import FormPlayerRegistration from './UI/Forms/FormPlayerRegistration';
import FormAuth from './UI/Forms/FormAuth';
import FormEdit from './UI/Forms/FormEdit';

export default {
  name: 'Header',
  components: {
    modal: Modal,
    'v-button': Button,
    'form-streamer-reg': FormStreamerRegistration,
    'form-player-reg': FormPlayerRegistration,
    'form-auth': FormAuth,
    'form-edit': FormEdit,
  },

  data() {
    return {
      isAuthorized: false,
      participantType: null,
      streamerName: null,
      showMenu: false,
      activeTab: '',
      slots: {
        registrationSlot: false,
        authSlot: false,
        editSlot: false,
        passwordSlot: false,
      },
      token: '',
      userEmail: '',
      waitingResponse: false,
    };
  },

  created() {
    this.token = this.$route.params.token;
    const user = JSON.parse(localStorage.getItem('participant'));
    if (user) {
      this.participantType = user.logged_as;
      this.isAuthorized = true;
      if (this.participantType === 'player') {
        this.getParticipant();
      }
    }
  },

  mounted() {
    const header = document.getElementById('header');
    window.addEventListener('scroll', () => {
      if (window.scrollY > 1) {
        header.classList.add('header-scrolling');
      } else {
        header.classList.remove('header-scrolling');
      }
    });
  },

  watch: {
    isAuthorized(val) {
      const user = JSON.parse(localStorage.getItem('participant'));
      this.streamerName = user.name;
      this.$root.$emit('checkAuthorized', val);
    },
  },

  computed: {
    ...mapState('account', ['error', 'user']),
    ...mapState({
      status: (state) => state.account.status,
    }),
  },

  methods: {
    ...mapActions('account', ['register']),
    ...mapActions('account', ['updateUser']),
    ...mapActions('account', ['login', 'logout']),
    ...mapActions('account', {
    }),
    ...mapActions('account', {
      getParticipant: 'getParticipant',
    }),
    headerBg(arg) {
      const header = document.getElementById('header').classList;
      return arg ? header.add('menu-open') : header.remove('menu-open');
    },

    // mobile menu
    toggleMenu() {
      this.showMenu = !this.showMenu;
      this.headerBg(this.showMenu);
    },
    closeMenu() {
      this.showMenu = false;
      this.headerBg(this.showMenu);
    },

    // modal
    controlModal() {
      this.$refs.modal.openModal();
      this.$nextTick(() => {
        this.activeTab = 'streamer';
      });
    },
    controlEditModal() {
      this.$refs.editModal.openModal();
    },

    // forms
    toggleRegViewsHandler(val) {
      this.$refs[val].reset();
    },

    // slots
    slotsSwitcher(slot) {
      for (const i in this.slots) {
        this.slots[i] = i === slot;
      }
    },

    // reg
    registration() {
      const self = this;
      this.waitingResponse = true;
      let params = { participant_type: this.activeTab };
      let form = null;
      if (this.activeTab === 'streamer') {
        params = Object.assign(params, { data: this.$refs.streamerRegForm.transmitParams() });
        form = this.$refs.streamerRegForm;
      } else {
        params = Object.assign(params, { data: this.$refs.playerRegForm.transmitParams() });
        form = this.$refs.playerRegForm;
      }
      this.register(params).then(() => {
        if (self.status) {
          form.reset();
          self.$refs.modal.closeModal();
        }
        self.waitingResponse = false;
      });
    },

    auth() {
      const self = this;
      this.waitingResponse = true;
      const params = this.$refs.authForm.transmitParams();
      const promise = this.login(params);
      promise.then(() => {
        self.waitingResponse = false;
        self.participantType = self.user ? self.user.logged_as : undefined;
        self.isAuthorized = !!self.user;
        if (self.isAuthorized && self.participantType === 'player') {
          self.getParticipant();
        }
        if (self.isAuthorized) {
          this.streamerName = self.user.name;
          self.$refs.modal.closeModal();
        }
      });
    },

    async logOut() {
      await this.logout();
      this.isAuthorized = false;
    },

    async saveChangesInProfile() {
      this.waitingResponse = true;
      await this.updateUser(this.$refs.formEditProfile.transmitParams());
      if (this.status) {
        this.$refs.editModal.closeModal();
      }
      this.waitingResponse = false;
      this.getParticipant();
    },

    callRegModal(switcherMethod) {
      this.controlModal();
      this.slotsSwitcher(switcherMethod);
    },
  },
};
</script>
<style lang="scss">
  .header-dropdown-toggler{
    margin-left: 20px;
    margin-right: 20px;
    @media (max-width: 340px) {
      margin-left: 5px;
      margin-right: 10px;
    }
  }

  //DESKTOP

  .header {
    padding: 30px 0;
    transition: all .4s;
    min-width: 320px;

    @include md {

    }
    @include sm {
      padding: 10px 0;
    }
    &.menu-open{
      background: $block-bg-color;
    }
    &.fixed-top {
      position: fixed;
      top: 0;
      width: 100%;
      left: 0;
      z-index: 100;
    }
    &.header-scrolling{
      background: rgba(0, 2, 37, 0.7);
      padding: 10px 0;
    }
    &__content{
      clear: both;
      display: flex;
      justify-content: space-between;
      position: relative;
      padding: 0 30px;
      width: 100%;
      @include md {
        padding: 0 24px;
      }
      @include sm {
        padding: 0 16px;
      }
    }
    .menu-icon{
      color: $normal-white;
      display: none;
      @include sm {
        display: flex;
        align-items: center;
        z-index: 10;
        transform: scale(1.5);
        margin-right: 20px;
      }
      @media(max-width: 576px){
        transform: scale(1);
        margin-right: 10px;
      }
      @media(max-width: 280px) and (min-height: 320px) {
        transform: scale(.9);
        margin-left: 5px;
        margin-right: 5px;
      }
    }
    &__menu {
      align-self: center;
      text-align: center;
      @include md {
      }
      @include sm {
        display: none;
      }
    }
    .desktop-nav__link {
      white-space: nowrap;
      margin-right: 30px;
      font-size: 20px;
      font-style: normal;
      line-height: 24px;
      transition: all .5s;
      @include md {
      }
      @include sm {
      }
    }
    &__buttons {
      text-align: right;
      margin-right: -16px;
      display: flex;
      align-items: center;
      .btn{
        margin: 0 16px;
        @media (max-width: 1525px) {
          margin: 0 12px;
        }
      }
      @media (max-width: 768px) {
        justify-content: space-between;
        width: 100%;
        margin-right: 0px;
        .btn{
          margin: 0 4px;
          &:nth-last-of-type(1){
            margin-right: 0;
          }
        }
      }
    }
  }

  // MOBILE

  .nav{
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .header-scrolling .nav-mobile{
    top: 59px;
    padding-top: 20px;
    @include sm {
      top: 0;
      &.open {
        flex-direction: column;
        height: 100vh;
      }
    }
  }
  .nav-mobile {
    display: none;
    @include sm {
      display: flex;
      justify-content: center;
      position: absolute;
      z-index: -1;
      width: 100%;
      top: 0;
      height: 0;
      background: $block-bg-color;
      padding-top: 0;
      &.open{
        padding-bottom: 32px;
        flex-direction: column;
        height: 100vh;
      }
    }
    .nav {
      height: auto;
      display: flex;
      flex-direction: column;
      margin-top: 60px;
      padding-bottom: 72px;
      width: 100%;
      position: relative;
      overflow-y: scroll;
      .nav__item {
        text-align: center;
        margin: 0 14px;
        font-size: 16px;
        + .nav__item {
          margin-top: 32px;
        }
        .nav__link{
          font-size: 16px;
          &:hover {
            color: $normal-gray;
          }
          &:active {
            color: $primary-color;
          }
        }
      }
    }
  }

  // NOTIFICATION

  .notify {
    .point {
      width: 10px;
      height: 10px;
      border-radius: 30px;
      background-color: #04D3FD;
      position: absolute;
      right: 1px;
      top: -1px;
    }
  }

  // MODAL TABS

  .modal-tabs{
    width: 100%;
    overflow: hidden;
    .modal-tab{
      position: relative;
      display: inline-block;
      padding: 24px 10px;
      width: 50%;
      font-family: "Avenir-Black", sans-serif;
      font-size: 18px;
      color: $dark-gray;
      text-align: center;
      cursor: pointer;
      @media(max-width: 576px){
        padding: 14px 10px;
        font-size: 14px;
      }
      &:before{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        background: $page-bg-color;
      }
      &:hover{
        color: $normal-gray;
      }
      &.active{
        color: $normal-white;
        &:before{
          background: $primary-color;
        }
      }
    }
  }

  // vue classes

  .menu-enter-active, .menu-leave-active {
    @media (max-width: 1024px) {
      transition: top .4s, height .4s;
    }
    @media (max-width: 768px) {
      left: 0%;
      transition: transform .4s, opacity .4s, left .4s, border-radius .4s;
    }
  }
  .header-scrolling .menu-enter,
  .header-scrolling .menu-leave-to {
    opacity: 0;
    @media (max-width: 1024px) {
      top: 40px;
      height: 0px;
    }
  }
  .menu-enter, .menu-leave-to{
    opacity: 0;
    @media (max-width: 1024px) {
      top: 66px;
      height: 0px;
    }
    @media(max-width: 768px){
      transform: scale(2);
      left: -100%;
      border-bottom-right-radius: 300%;
      border-top-right-radius: 300%;
    }
  }
  .icon-enter-active{
    transition: transform .3s;
  }
  .icon-enter {
    transform: scale(0);
  }
  .slide-enter-active{
    transition: opacity 1s;
  }
  .slide-enter{
    opacity: 0;
  }
</style>
