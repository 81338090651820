<template>
  <div
    class="form"
    ref="form"
    name="commonForm"
    id="commonForm"
    action=""
  >
    <div>
      <div class="form-group__item">
        <input
          v-model="login"
          type="text"
          class="form-control"
          placeholder="Login"
        >
      </div>
      <div class="form-group__item">
        <input
          v-model="name"
          type="text"
          class="form-control"
          placeholder="Poreclă"
          autocomplete="no"
        >
      </div>
      <div class="form-group__item">
        <input
          type="password"
          v-model="password"
          class="form-control"
          placeholder="Parolă"
          autocomplete="new-password"
        >
      </div>
      <div class="form-group__item">
        <input
          type="password"
          v-model="password_confirmation"
          class="form-control"
          placeholder="Confirmă parola"
          autocomplete="new-password"
        >
      </div>
      <div class="form-group">
        <div class="form-group__item m-b-10">
          <multiselect
            v-model="streamer"
            :options="streamersList"
            :close-on-select="true"
            :show-labels="false"
            :max-height="150"
            placeholder="Streamere"
            track-by="id"
            label="name"
            :loading="isLoading"
            @open="getAllStreamers"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="form-group__item">
          <div
            v-if="projects.slotv"
            class="action-combined"
          >
            <radio-button
              label="slotv"
              :value="main_project"
              @change="chooseMainProject"
            />
          </div>
          <input
            type="email"
            class="form-control email-placeholder"
            :class="{'input-combined' : projects.slotv}"
            v-model="projects.slotv"
            @input="switchManinProject($event, 'slotv')"
            placeholder="SlotV email"
          >
        </div>
        <div class="form-group__item">
          <div
            class="action-combined"
            v-if="projects.frank"
          >
            <radio-button
              label="frank"
              :value="main_project"
              @change="chooseMainProject"
            />
          </div>
          <input
            type="email"
            class="form-control email-placeholder"
            :class="{'input-combined' : projects.frank}"
            v-model="projects.frank"
            @input="switchManinProject($event, 'frank')"
            placeholder="Frank email"
          >
        </div>
        <div class="form-group__item">
          <div
            class="action-combined"
            v-if="projects.mrbit"
          >
            <radio-button
              label="mrbit"
              :value="main_project"
              @change="chooseMainProject"
            />
          </div>
          <input
            type="email"
            :class="{'input-combined' : projects.mrbit}"
            class="form-control email-placeholder"
            placeholder="MrBit email"
            v-model="projects.mrbit"
            @input="switchManinProject($event, 'mrbit')"
          >
        </div>
      </div>
      <div class="form-group">
        <p class="text-gray">
          Te rugăm alege unde vrei să primești recompensa.
        </p>
        <p class="text-gray">
          Premiul va fi creditat în <span class="text-white">{{ getMainCreditProject }}</span> contul tău. .
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapState, mapActions } from 'vuex';
import RadioButton from './RadioButton';

export default {
  name: 'FormPlayerRegistration',
  components: {
    Multiselect,
    RadioButton,
  },
  data() {
    return {
      login: '',
      name: '',
      streamer: null,
      password: '',
      password_confirmation: '',
      streamersList: [],
      isLoading: false,
      projects: {
        frank: '',
        slotv: '',
        mrbit: '',
      },
      main_project: null,
    };
  },
  computed: {
    ...mapState({
      streamers: (state) => state.streamers.items,
    }),
    getMainCreditProject() {
      return this.main_project ? this.main_project[0].toUpperCase() + this.main_project.slice(1) : 'Neselectat';
    },
  },
  methods: {
    ...mapActions('streamers', {
      getAllStreamersAutocomplete: 'getAllAutocomplete',
    }),
    ...mapActions('streamers', {
      getAll: 'getAll',
    }),
    switchManinProject(val, project) {
      if (val.target.value === '' && this.main_project === project) {
        this.main_project = null;
      }
    },
    reset() {
      this.login = '';
      this.streamer = null;
      this.streamersList = [];
      this.password = '';
      this.password_confirmation = '';
      this.name = '';
      for (const i in this.projects) {
        this.projects[i] = '';
      }
      this.main_project = null;
    },
    getAllStreamers() {
      const self = this;
      if (!this.streamersList.length) {
        this.isLoading = true;
        this.getAll().then(() => {
          self.streamersList = self.streamers;
          self.isLoading = false;
        });
      }
    },
    prepareProjects() {
      // eslint-disable-next-line no-unused-vars
      return Object.fromEntries(Object.entries(this.projects).filter(([_, v]) => v !== ''));
    },
    chooseMainProject(val) {
      this.main_project = val;
    },
    transmitParams() {
      return {
        login: this.login,
        name: this.name,
        streamer_id: this.streamer ? this.streamer.id : null,
        password: this.password,
        password_confirmation: this.password_confirmation,
        projects: this.prepareProjects(),
        main_project: this.main_project,
      };
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  .multiselect__tags {
    min-height: 32px;
    display: block;
    padding: 0 40px 0 0;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $page-bg-color;
    background: transparent;
    font-size: 14px;
  }
  .multiselect__input, .multiselect__single {
    position: relative;
    display: inline-block;
    min-height: 20px;
    line-height: 20px;
    border: none;
    color: $normal-white;
    background: transparent;
    padding: 0 ;
    width: 100%;
    -webkit-transition: border .1s ease;
    transition: border .1s ease;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 8px;
    vertical-align: top;
  }
  .multiselect__placeholder {
    color: $normal-gray;
    display: inline-block;
    margin-bottom: 10px;
    padding-top: 2px;
  }
  .multiselect__content-wrapper {
    position: absolute;
    display: block;
    background: $page-bg-color;
    width: 100%;
    max-height: 240px;
    overflow: auto;
    border: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 50;
    -webkit-overflow-scrolling: touch;
  }
  .multiselect__option--highlight {
    background: transparent;
    outline: none;
    color: $primary-color;
  }
  .multiselect__option--selected.multiselect__option--highlight {
    background: transparent;
    color: $normal-white;
  }
  .multiselect__option--selected {
    background: transparent;
    color: $normal-white;
    font-weight: 700;
  }
  .multiselect__select {
    position: absolute;
    height: 34px;
    right: 1px;
    top: -6px;
    padding: 4px 8px;
    border-radius: 0;
    text-align: center;
    transition: transform .2s ease;
  }
  .multiselect__select:before {
    background: url("../../../assets/img/icons/icon_arrow.svg") center no-repeat;
    border: none;
    height: 18px;
    left: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
    top: 50%;
    width: 18px;
    content: "";
    margin: 0;
  }
  .multiselect--active .multiselect__select{
    transform: rotate(180deg);
  }
  .multiselect__content-wrapper::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }
  .multiselect__content-wrapper::-webkit-scrollbar,.multiselect__content-wrapper::-webkit-scrollbar-track {
    background: $dark-gray;
    border-radius: 3px;
    border-radius: 3px;
  }
  .multiselect__content-wrapper::-webkit-scrollbar-thumb {
    background: $primary-color;
    border-radius: 3px;
    border-radius: 3px;
  }
  .multiselect__spinner {
    position: absolute;
    right: 1px;
    top: -8px;
    width: 48px;
    height: 35px;
    background: $block-bg-color;
    display: block;
  }
  .multiselect__spinner:after, .multiselect__spinner:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 2px solid transparent;
    border-top-color: $primary-color;
    -webkit-box-shadow: 0 0 0 1px transparent;
    box-shadow: 0 0 0 1px transparent;
  }
  .multiselect__loading-enter-active,
  .multiselect__loading-leave-active {
    transition: opacity 0.1s ease-in-out;
    opacity: 1;
  }
  .multiselect__loading-enter,
  .multiselect__loading-leave-active {
    opacity: 0;
  }
  .multiselect--above .multiselect__content-wrapper {
    border-top: 0;
  }
</style>
