import { authHeader } from '../_helpers';
import { ResponseHandler } from '../_helpers/response-handler';
import { prepeareUrl } from '../_helpers/request-helpers';

export const userService = {
  login,
  register,
  logout,
  getParticipant,
  updateUser,
};

function logout() {
  const user = JSON.parse(localStorage.getItem('participant'));
  if (user === null) {
    return;
  }
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.token}`,
    },
  };
  const url = prepeareUrl('/logout');
  fetch(url, requestOptions).then(handleResponse);
  localStorage.removeItem('participant');
  window.location.replace('/');
}

function login(login, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ login, password }),
  };
  const url = prepeareUrl('/login');
  return fetch(url, requestOptions).then(handleResponse);
}
function register(params) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params.data),
  };
  const url = prepeareUrl(`/register/${params.participant_type}`);
  return fetch(url, requestOptions).then((res) => handleResponse(res, 'Please login'));
}

function getParticipant() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const url = prepeareUrl('/profile');
  return fetch(url, requestOptions).then(handleResponse);
}

function updateUser(data) {
  const user = JSON.parse(localStorage.getItem('participant'));
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.token}`,
    },
    body: JSON.stringify(data),
  };
  const url = prepeareUrl('/update/player');
  const res = fetch(url, requestOptions).then(handleResponse);
  return res;
}

function handleResponse(response, msg = null) {
  return response
    .text()
    .then((text) => ResponseHandler.parseResponse(text, response.status, msg));
}
